<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_distributionProcessingMasterRegistList" />
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <!-- １行目スタート -->
          <v-row class="search-row">
            <!-- ユーザーメーカー区分 -->
            <div class="first-search-item" style="float: left">
              <v-btn-toggle
                mandatory
                v-model="userMakerSected"
                color="blue darken-2"
                id="btn-toggle-btn-padding"
              >
                <v-btn
                  class="paid-btn"
                  v-for="item in userMakerList"
                  :key="item.value"
                  :value="item.value"
                  small
                >
                  {{ item.text }}</v-btn
                >
              </v-btn-toggle>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 取引先 -->
            <div class="search-autocomplete">
              <v-autocomplete
                dense
                v-model="suppliersSelected"
                :items="supplierList"
                :label="userMaler + $t('label.lbl_supplier')"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                :hint="setSupplier()"
                :rules="[rules.supplierRequired]"
                pa-3
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
            <span class="require">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 品番 -->
            <div class="search-textbox" style="float: left">
              <v-text-field
                dense
                outlined
                v-model="itemSelected"
                :label="userMaler + $t('label.lbl_productCode')"
                persistent-hint
                clear-icon="mdi-close-circle"
                clearable
                maxlength="50"
              >
              </v-text-field>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 付帯品番 -->
            <div class="search-textbox" style="float: left">
              <v-text-field
                dense
                outlined
                v-model="accessoryProduct"
                :label="userMaler + $t('label.lbl_accessoryProduct')"
                persistent-hint
                clear-icon="mdi-close-circle"
                clearable
                maxlength="50"
              >
              </v-text-field>
            </div>
          </v-row>
          <v-row>
            <div class="search-row-exeBtn">
              <!--ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 検索ボタン -->
                <v-btn
                  color="primary"
                  id="get-search"
                  class="api-btn"
                  @click="getItemList('get-search')"
                  >{{ $t("btn.btn_search") }}</v-btn
                >
              </div>
              <!-- 画面クリアボタン -->
              <v-btn id="btn-pack" class="post-btn" @click="clearScreen">
                {{ $t("btn.btn_clear") }}
              </v-btn>
            </div>
          </v-row>
          <v-row>
            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <span>総件数：{{ totalCount }}件</span>
              </div>

              <!--表示件数切替部分-->
              <div style="float: right">
                <!--表示件数-->
                <div class="text-label" style="clear: both; float: left">
                  {{ $t("label.lbl_itemsPerPage") }}
                </div>
                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>

                <!--表示件数切替ボタン-->
                <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                    mandatory
                  >
                    <v-btn class="pasing-btn" value="100" @click="u_10_on()" small>
                      {{ 100 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="250" @click="u_10_clear(250)" small>
                      {{ 250 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="500" @click="u_10_clear(500)" small>
                      {{ 500 }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>

      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :page.sync="page"
          :server-items-length="itemsPerPage"
          disable-filtering
          :options.sync="sortOptions"
          :hide-default-footer="true"
          height="530px"
        >
          <!-- ヘッダ項目 -->
          <!-- ロット有無 -->
          <template v-slot:[`header.lotCnt`]="{ header }">
            <v-row>
              <v-col style="text-align: center">
                <div>{{ header.text }}</div>
                <div>{{ $t("label.lbl_availability") }}</div>
              </v-col>
            </v-row>
          </template>
          <!-- S/N有無 -->
          <template v-slot:[`header.serialCnt`]="{ header }">
            <v-row>
              <v-col style="text-align: center">
                <div>{{ header.text }}</div>
                <div>{{ $t("label.lbl_availability") }}</div>
              </v-col>
            </v-row>
          </template>
          <!-- 部品構成 -->
          <template v-slot:[`header.partsExistence`]="{ header }">
            <v-row>
              <v-col style="text-align: center">
                <div>{{ header.text }}</div>
                <div>{{ $t("label.lbl_composition") }}</div>
              </v-col>
            </v-row>
          </template>
          <!-- 作業手順 -->
          <template v-slot:[`header.workProcedure`]="{ header }">
            <v-row>
              <v-col style="text-align: center">
                <div>{{ header.text }}</div>
                <div>{{ $t("label.lbl_procedure") }}</div>
              </v-col>
            </v-row>
          </template>
          <!-- 発注点管理 -->
          <template v-slot:[`header.orderingPointManage`]="{ header }">
            <v-row>
              <v-col style="text-align: center">
                <div>{{ header.text }}</div>
                <div>{{ $t("label.lbl_management") }}</div>
              </v-col>
            </v-row>
          </template>
          <!-- 検査内容 -->
          <template v-slot:[`header.contentsOfInspection`]="{ header }">
            <v-row>
              <v-col style="text-align: center">
                <div>{{ header.text }}</div>
                <div>{{ $t("label.lbl_content") }}</div>
              </v-col>
            </v-row>
          </template>
          <!-- 明細項目 -->
          <!-- 取引先 -->
          <template v-slot:[`item.supplierCd`]="{ item }">
            <div v-if="item.supplierCd">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="text-overflow" style="max-width: 330px" v-bind="attrs" v-on="on">
                    {{ item.supplierCd }}<br />
                    {{ item.clientName }}
                  </div>
                </template>
                <div>{{ item.supplierCd }}<br />{{ item.clientName }}</div>
              </v-tooltip>
            </div>
          </template>
          <!-- 商品コード・付帯品番 -->
          <template v-slot:[`item.productCnCd`]="{ item }">
            <div v-if="item.productCnCd.length > 35">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="text-overflow" style="max-width: 330px" v-bind="attrs" v-on="on">
                    {{ item.productCnCd }}<br />
                    {{ item.accessoryProductCd }}
                  </div>
                </template>
                <div>{{ item.productCnCd }}<br />{{ item.accessoryProductCd }}</div>
              </v-tooltip>
            </div>
            <div v-else>
              {{ item.productCnCd }}<br />
              {{ item.accessoryProductCd }}
            </div>
          </template>
          <!--品名-->
          <template v-slot:[`item.productName`]="{ item }">
            <div v-if="item.productName.length > 35">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="text-overflow" style="max-width: 365px" v-bind="attrs" v-on="on">
                    {{ item.productName }}
                  </div>
                </template>
                <div>{{ item.productName }}</div>
              </v-tooltip>
            </div>
            <div v-else>
              <div>{{ item.productName }}</div>
            </div>
          </template>
          <!-- 作業工程パターン -->
          <template v-slot:[`item.procPattern`]="{ item, index }">
            <a @click="procPatternSearch(item, index)" :style="item.workProcPatternStyle">{{
              item.procPattern
            }}</a>
          </template>
          <!-- 作業手順-->
          <template v-slot:[`item.workProcedure`]="{ item, index }">
            <a @click="workProcedureSelect(index)" :style="item.workProcRegStyle">{{
              item.workProcedure
            }}</a>
          </template>
          <!-- 検査内容 -->
          <template v-slot:[`item.contentsOfInspection`]="{ item, index }">
            <a @click="inspectionSelect(index)" :style="item.inspectionRegStyle">{{
              item.contentsOfInspection
            }}</a>
          </template>
        </v-data-table>
        <v-dialog v-model="updateDialogMessage" :max-width="800">
          <!-- 警告ダイアログ -->
          <v-card>
            <v-card-title class="blue-grey lighten-3" primary-title>
              {{ $t("btn.btn_ok") }}
            </v-card-title>
            <v-card-text class="pa-4">
              <p>{{ backMessage }}</p>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="updateOk"> OK </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
        </div>
      </v-container>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <!-- 確認メッセージダイアログモーダル-->
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
        :homePageFlag="infoDialog.homePageFlag"
        :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
      />
      <ConfirmDialog
        :isShow.sync="confirmDialog.isOpen"
        :message="confirmDialog.message"
        :screenFlag="confirmDialog.screenFlag"
        :okAction="confirmDialog.okAction"
        :redMessage="confirmDialog.redMessage"
        :changeFlag="confirmDialog.changeFlag"
      />
      <!-- 作業工程ﾊﾟﾀｰﾝ選択画面ポップアップ -->
      <v-dialog v-model="openDatailsAdd" :max-width="1000">
        <v-card>
          <v-card-title id="sub-bar">
            <span id="lbl-screen-name">{{ $t("label.lbl_procPatternSelect") }}</span>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-form ref="detailPopup" lazy-validation>
                <v-data-table
                  id="listData"
                  fixed-header
                  :headers="procPatternHeaderItems"
                  :items="procPatternList"
                  disable-filtering
                  :hide-default-footer="true"
                  height="470px"
                >
                  <!-- 選択 -->
                  <template v-slot:[`item.procSelect`]="{ index }">
                    <input
                      type="radio"
                      v-model="procSelect"
                      :value="index"
                      style="transform: scale(2)"
                    />
                  </template>
                </v-data-table>
              </v-form>

              <v-row style="margin-bottom: 5px; margin-top: 15px">
                <div class="search-row-exeBtn">
                  <div class="btn-area">
                    <!-- 閉じるボタン -->
                    <v-btn
                      color="primary"
                      id="btn-search"
                      class="other-btn"
                      @click="closeDetailsInfo()"
                    >
                      {{ $t("btn.btn_close") }}</v-btn
                    >
                    <!-- 追加ボタン -->
                    <v-btn
                      color="primary"
                      id="btn-search"
                      class="other-btn"
                      @click="addProcPattern()"
                    >
                      {{ $t("追加") }}</v-btn
                    >
                    <!-- 登録ボタン -->
                    <v-btn
                      color="primary"
                      id="btn-search"
                      class="other-btn"
                      @click="regist()"
                      :disabled="procSelect == null"
                    >
                      {{ $t("btn.btn_insert") }}</v-btn
                    >
                  </div>
                </div>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- 作業工程ﾊﾟﾀｰﾝ追加画面ポップアップ -->
      <v-dialog v-model="openProcPatternAdd" :max-width="600">
        <v-card>
          <v-card-title id="sub-bar">
            <span id="lbl-screen-name">{{ $t("label.lbl_procPatternAdd") }}</span>
          </v-card-title>
          <v-card-text>
            <v-form ref="ProcPatternAddPopup" lazy-validation>
              <v-container fluid>
                <!-- １行目スタート -->
                <v-row class="search-row">
                  <!-- パターン -->
                  <div class="search-textbox" style="float: left">
                    <v-text-field
                      dense
                      outlined
                      v-model="pattern"
                      :label="$t('label.lbl_pattern')"
                      persistent-hint
                      clear-icon="mdi-close-circle"
                      clearable
                      :rules="[rules.inputRequired, rules.isNumber]"
                      maxlength="3"
                    >
                    </v-text-field>
                  </div>
                  <!-- 必須 -->
                  <span class="require">*</span>

                  <div class="search-row-exeBtn">
                    <div class="btn-area">
                      <!-- 閉じるボタン -->
                      <v-btn
                        color="primary"
                        id="btn-search"
                        class="other-btn"
                        @click="closeProcPattern()"
                      >
                        {{ $t("btn.btn_close") }}</v-btn
                      >
                      <!-- 登録ボタン -->
                      <v-btn
                        color="primary"
                        id="btn-search"
                        class="other-btn"
                        @click="registProcPatternMsg()"
                      >
                        {{ $t("btn.btn_insert") }}</v-btn
                      >
                    </div>
                  </div>
                </v-row>
              </v-container>
            </v-form>
            <v-container fluid>
              <v-form ref="ProcPatternList" lazy-validation>
                <v-data-table
                  id="listProcData"
                  fixed-header
                  :headers="procPatterAddHeaderItems"
                  :items="procList"
                  disable-filtering
                  disable-pagination
                  :hide-default-footer="true"
                  height="430px"
                >
                  <template v-slot:[`item.order`]="{ item }">
                    <v-text-field
                      outlined
                      dense
                      v-model="item.order"
                      class="txt-single input-number"
                      clear-icon="mdi-close-circle"
                      clearable
                      :rules="[rules.isNumber]"
                      maxlength="3"
                    >
                    </v-text-field>
                  </template>
                </v-data-table>
              </v-form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-sheet>
  </div>
</template>

<script>
import NavBar from "../../components/NavBar.vue";
import SimpleDialog from "@/components/SimpleDialog";
import Loading from "@/components/loading";
import sideMenu from "@/components/Menu";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { i18n } from "../../lang/lang.js";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import commonMixin from "@/mixins/CommonMixin";
import commonRules from "@/mixins/CommonRules";
import ConfirmDialog from "@/components/ConfirmDialog";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";

export default {
  name: appConfig.MENU_ID.P_MST_ITEM_MASTER_LIST,
  components: {
    Loading,
    sideMenu,
    SimpleDialog,
    NavBar,
    ConfirmDialog,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  mixins: [commonMixin, commonRules],
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // 作業工程パターン選択画面ポップアップ
    openDatailsAdd: false,
    u_10: false,
    // 検索ボタン押下後、trueにする
    // ボタン表示の切り替え
    btnToggle: false,
    // 入力チェック結果フラグ
    isInputCheck: true,
    // 総件数
    totalCount: 0,
    // ページング
    itemsPerPageList: [100, 250, 500],
    page: 1,
    pageCount: 1,
    itemsPerPage: 100,
    itemsPerPageBtn: "100",
    // メニュー
    openMenu: null,
    // 品番
    itemSelected: "",
    //付帯品番
    accessoryProduct: "",
    // 検索条件保持
    userMakerKbnSession: "",
    supplierSession: "",
    itemCdSession: "",
    itemSubCdSession: "",
    //受け渡し用品名
    productSelectedName: "",

    // ユーザーメーカー区分
    userMakerSected: "01",
    userMakerSectedHint: "",
    userMakerList: [],
    userMaler: "",
    // 取引先
    supplierList: [],
    defaultData: [], // 初期データ
    suppliersSelected: "",
    suppliersSelectedName: "",
    // 一覧選択チェックボックス
    listCheckbox: [1, 2],
    // 登録状況リスト
    inputList: [],
    // 登録リスト
    registList: [],
    // 作業工程リスト
    procPatternList: [],
    // 工程リスト
    procList: [],
    // ソート
    sortItem: "",
    sortOptions: {},
    confirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
    },
    updateDialogMessage: false, // 戻るボタンダイアログ
    backMessage: "", // 戻るボタンダイアログメッセージ
    procSelect: null, //選択取引先
    // 配列のインデックス
    index: 0,
    item: "",
    pattern: "",
    openProcPatternAdd: false,

    // テーブルヘッダ項目
    headerItems: [
      // 取引先
      {
        text: i18n.tc("label.lbl_supplier"),
        value: "supplierCd",
        width: "20%",
        align: "left",
        sortable: false,
      },
      // 品番/付帯品番
      {
        text: i18n.tc("label.lbl_productCode") + "/" + i18n.tc("label.lbl_accessoryProduct"),
        value: "productCnCd",
        width: "20%",
        align: "left",
        sortable: false,
      },
      // 品名
      {
        text: i18n.tc("label.lbl_productCodeName"),
        value: "productName",
        width: "22%",
        align: "left",
        sortable: false,
      },
      // ロット有無
      {
        text: i18n.tc("label.lbl_lot"),
        value: "lotCnt",
        align: "center",
        width: "5%",
        sortable: false,
      },
      // S/N有無
      {
        text: i18n.tc("label.lbl_serial"),
        value: "serialCnt",
        align: "center",
        width: "5%",
        sortable: false,
      },
      // 発注点管理
      {
        text: i18n.tc("label.lbl_orderingPoint"),
        value: "orderingPointManage",
        align: "center",
        width: "6%",
        sortable: false,
      },
      // 部品構成
      {
        text: i18n.tc("label.lbl_parts"),
        value: "partsExistence",
        align: "center",
        width: "5%",
        sortable: false,
      },
      // 作業工程パターン
      {
        text: i18n.tc("label.lbl_procPattern"),
        value: "procPattern",
        align: "center",
        width: "7%",
        sortable: false,
      },
      // 作業手順
      {
        text: i18n.tc("label.lbl_work"),
        value: "workProcedure",
        align: "center",
        width: "5%",
        sortable: false,
      },
      // 検査内容
      {
        text: i18n.tc("label.lbl_inspection"),
        value: "contentsOfInspection",
        align: "center",
        width: "5%",
        sortable: false,
      },
    ],
    // テーブルヘッダ項目
    procPatternHeaderItems: [
      // 選択
      {
        text: i18n.tc("label.lbl_choice"),
        value: "procSelect",
        width: "10%",
        align: "center",
        sortable: false,
      },
      // パターン
      {
        text: i18n.tc("label.lbl_pattern"),
        value: "pattern",
        width: "20%",
        align: "center",
        sortable: false,
      },
      // 作業工程
      {
        text: "作業工程",
        value: "proc",
        width: "70%",
        align: "left",
        sortable: false,
      },
    ],
    // テーブルヘッダ項目
    procPatterAddHeaderItems: [
      // 順番
      {
        text: i18n.tc("label.lbl_order"),
        value: "order",
        width: "40%",
        align: "left",
        sortable: false,
      },
      // 工程
      {
        text: i18n.tc("label.lbl_opeHist"),
        value: "procName",
        width: "60%",
        align: "left",
        sortable: false,
      },
    ],
  }),
  methods: {
    // ページング
    u_10_check() {
      if (this.itemsPerPage < 101) {
        this.u_10 = true;
      }
    },
    u_10_on() {
      this.u_10 = true;
      this.itemsPerPage = 100;
      this.getItemList("get-search");
    },
    u_10_clear(val) {
      this.u_10 = false;
      this.itemsPerPage = val;
      this.getItemList("get-search");
    },

    // 初期化
    init() {
      this.defaultData = Object.assign({}, this.$data);
      this.itemsPerPage = 100;
      this.itemsPerPageBtn = "100";
      this.getCustomInfo();
    },

    // 登録ボタン押下
    regist() {
      // 登録されている工程パターンと同じかチェック
      if (this.procPatternList[this.procSelect].pattern == this.inputList[this.index].procPattern) {
        // エラーメッセージを表示
        this.updateDialogMessage = true;
        this.backMessage = messsageUtil.getMessage("P-RCV-006_001_E");
      } else {
        //登録
        this.registList = [];
        this.registList.push({
          itemStandardSid: this.inputList[this.index].itemStandardSid, //順番
          procPattern: this.procPatternList[this.procSelect].pattern, //工程コード
        });
        this.confirmDialog.message = messsageUtil.getMessage("P-RCV-001_003_C");
        this.confirmDialog.isOpen = true;
        this.confirmDialog.screenFlag = true;
        this.confirmDialog.changeFlag = false;
        this.confirmDialog.okAction = this.registDpItem;
      }
    },
    // 登録ボタン押下
    registDpItem() {
      //登録
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // httpClientで使用する共通IFパラメータ等を作成する。
      const body = this.$httpClient.createRequestBodyConfig();
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_INI_008; // 画面ID
      // パラメータ設定
      body.reqIdv.procAddList = this.registList; //取引先コード

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.INI_DP_PROC_PATTERN_UPDATE, body, appConfig.APP_CONFIG)

          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              this.userMakerSected = this.userMakerKbnSession;
              this.suppliersSelected = this.supplierSession;
              this.itemSelected = this.itemCdSession;
              this.accessoryProduct = this.itemSubCdSession;
              this.getItemList("get-search");
              this.openDatailsAdd = false;
              this.infoDialog.message = messsageUtil.getMessage("P-COM-001_005_C", [
                i18n.tc("btn.btn_insert"),
              ]);
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              this.infoDialog.outsideClickNotCloseFlg = false;
              // エラー時
            } else {
              reject(new Error(jsonData.resCom.resComMessage));
            }
          })
          .catch((ex) => {
            reject(ex);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      }).catch((ex) => {
        this.infoDialog.message = ex.message;
        this.infoDialog.title = appConfig.DIALOG.title;
        this.infoDialog.isOpen = true;
        this.infoDialog.screenFlag = true;
        this.infoDialog.firstPageFlag = true;
      });
    },
    // 検索ボタン押下後、商品マスタ一覧を取得
    getItemList(val) {
      // 入力チェック結果フラグ
      let inputCheckResult = false;

      /**
       * 登録画面からの遷移の場合、入力チェックを実施するとvalidateが定義されていないと
       * エラーがでるので回避するため、このようなコードとなっている。
       */
      if (this.$route.params.name == appConfig.SCREEN_ID.P_INI_009) {
        // 登録画面からの遷移の場合、入力チェックは実施しない。
        inputCheckResult = true;
      } else {
        // 上記外の場合はチェックを行う。
        inputCheckResult = this.$refs.form.validate();
      }

      if (inputCheckResult) {
        this.btnToggle = true;

        // ローディング画面表示ON
        this.loadingCounter = 1;

        //検索条件保持処理
        this.userMakerKbnSession = this.userMakerSected;
        this.supplierSession = this.suppliersSelected;
        this.itemCdSession = this.itemSelected;
        this.itemSubCdSession = this.accessoryProduct;

        const config = this.$httpClient.createGetApiRequestConfig();

        // 営業所SID（ログインユーザ情報）
        config.params.officeSid = sessionStorage.getItem("sales_office_sid");
        //ユーザーメーカー区分
        config.params.userMakerKbn = this.userMakerSected;
        // 取引先SID
        config.params.clientSid = this.suppliersSelected;

        // 品番
        if (this.itemSelected) {
          config.params.itemCd = this.itemSelected;
        }
        //付帯品番
        if (this.accessoryProduct) {
          config.params.itemSubCd = this.accessoryProduct;
        }

        // ページャー処理
        if (val == "get-search") {
          config.params.reqComPageIndex = "1";
          this.page = 1;
        } else {
          if (this.page >= 2 && this.inputList.length == 0) {
            config.params.reqComPageIndex = "1";
            this.page = 1;
          } else {
            config.params.reqComPageIndex = this.page;
          }
        }
        // ページング
        config.params.reqComPaginationFlg = "1";
        config.params.reqComPageLimit = this.itemsPerPage;

        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .secureGet(appConfig.API_URL.INI_DP_MASTER_REGIST_STATUS_LIST, config)
            .then((response) => {
              const jsonData = JSON.parse(JSON.stringify(response.data));

              // 成功
              if (jsonData.resCom.resComCode == "000") {
                this.pageCount = jsonData.resCom.resComPagination.totalPage;
                this.totalCount = jsonData.resCom.resComPagination.totalRecord;
                const list = [];

                jsonData.resIdv.distributionProcessingStateList.forEach((row) => {
                  let lotAircraftnumberFlg = "";
                  let serialAircraftnumberFlg = "";
                  let orderingPointFlg = "";
                  let partsFlg = "";
                  let workProcPattern = "";
                  let workProcPatternStyle = "";
                  let workProcRegFlg = "";
                  let workProcRegStyle = "";
                  let inspectionRegFlg = "";
                  let inspectionRegStyle = "";
                  if (row.lotAircraftnumberFlg == "1") {
                    lotAircraftnumberFlg = "有";
                  } else {
                    lotAircraftnumberFlg = "無";
                  }
                  if (row.serialAircraftnumberFlg == "1") {
                    serialAircraftnumberFlg = "有";
                  } else {
                    serialAircraftnumberFlg = "無";
                  }

                  if (row.orderingPointFlg == "1") {
                    orderingPointFlg = "有";
                  } else {
                    orderingPointFlg = "無";
                  }

                  if (row.partsFlg == "1") {
                    partsFlg = "有";
                  } else {
                    partsFlg = "無";
                  }

                  if (row.workProcPattern == null) {
                    workProcPattern = "未";
                    workProcPatternStyle = "color: red;";
                  } else {
                    workProcPattern = row.workProcPattern;
                  }

                  let workContentSid = row.workContentSid ?? "";
                  workContentSid = workContentSid.replaceAll(" ", "");

                  if (workContentSid != "") {
                    workProcRegFlg = "有";
                  } else {
                    workProcRegFlg = "未";
                    workProcRegStyle = "color: red;";
                  }

                  let listGroup = row.listGroup ?? "".replaceAll(" ", "");

                  if (listGroup != "") {
                    inspectionRegFlg = "有";
                  } else {
                    inspectionRegFlg = "未";
                    inspectionRegStyle = "color: red;";
                  }

                  list.push({
                    clientSid: row.clientSid, //取引先SID
                    supplierCd: row.clientCd, //取引先コード
                    clientName: row.clientName, //取引先名
                    itemStandardSid: row.itemStandardSid, //商品Sid
                    productCnCd: row.itemCd, //品番
                    accessoryProductCd: row.itemSubCd, //付帯品番
                    productName: row.itemName, //品名
                    lotCnt: lotAircraftnumberFlg, //ロット有無
                    serialCnt: serialAircraftnumberFlg, //S/N有無
                    orderingPointManage: orderingPointFlg, //発注点管理
                    partsExistence: partsFlg, //部品構成
                    procPattern: workProcPattern, //作業工程パターン
                    workProcedure: workProcRegFlg, //作業手順
                    workContentSid: workContentSid, //作業手順SID
                    contentsOfInspection: inspectionRegFlg, //検査内容
                    listGroup: listGroup, //検査内容SID
                    workProcPatternStyle: workProcPatternStyle, //作業工程パターン用style
                    workProcRegStyle: workProcRegStyle, //作業手順用style
                    inspectionRegStyle: inspectionRegStyle, //検査内容用style
                    updateDatetime: row.updateDatetime, //更新日時
                  });
                });
                this.inputList = list;

                // 検索値保持
                Object.assign(this.searchFilter, {
                  suppliersSelected: this.suppliersSelected, // 取引先SID
                  itemSelected: this.itemSelected, // 品番
                  accessoryProduct: this.accessoryProduct, // 付帯品番
                });

                resolve(response);
              } else {
                // エラー時
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                this.infoDialog.firstPageFlag = true;

                reject();
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;

              reject();
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      }
    },
    /**
     * 画面クリアボタン押下時
     */
    clearScreen() {
      this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = this.clearScreenOkClick;
    },
    /**
     * 画面クリア
     */
    clearScreenOkClick() {
      this.confirmDialog.isOpen = false;
      this.$route.params.name = "";
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);

      // 初期化
      this.init();
      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
    },
    // ListAPI接続
    getCustomInfo(jstInScheduleDate) {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 取引先
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC(jstInScheduleDate)
      );
      // ユーザー・メーカー区分
      const userMakerList = getParameter.getCodeMst(appConfig.CODETYPE.USER_MAKER_SELECT);

      Promise.all([clientList, userMakerList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.supplierList = result[0];
          this.userMakerList = result[1];
          if (this.$route.params.name != appConfig.SCREEN_ID.P_INI_009) {
            this.userMaler = result[1][0].text;
          }
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          if (this.$route.params.name == appConfig.SCREEN_ID.P_INI_009) {
            this.userMakerSectedHint = this.$route.params.userMakerKbn;
            this.userMakerSected = this.$route.params.userMakerKbn;
            this.suppliersSelected = this.$route.params.supplier;
            this.itemSelected = this.$route.params.itemCd;
            this.accessoryProduct = this.$route.params.itemSubCd;
            this.getItemList("get-search");
          }
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    // ***プルダウンセット処理***
    // 取引先
    setSupplier() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          this.suppliersSelectedName = this.supplierList[i].text;
          return this.supplierList[i].name;
        }
      }
    },

    //作業工程リスト設定
    procPatternSearch(item, index) {
      this.index = index;
      this.item = item;
      this.procPatternSelect(item);
      //ポップアップ表示
      this.openDatailsAdd = true;
    },

    //作業工程リスト設定
    procPatternSelect(item) {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      // 取引先SID
      config.params.clientSid = this.suppliersSelected;

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.INI_DP_PROC_PATTERN_LIST, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 成功
            if (jsonData.resCom.resComCode == "000") {
              const list = [];

              jsonData.resIdv.procPatternList.forEach((row) => {
                var procName = "";
                for (var i = 0; i < row.procNameList.length; i++) {
                  if (i == 0) {
                    procName = row.procNameList[i];
                  } else {
                    procName = procName + "→" + row.procNameList[i];
                  }
                }

                list.push({
                  pattern: row.procPattern, //パターン
                  proc: procName, //作業工程
                });
              });
              this.procPatternList = list;
              this.procSelect = null;
              for (var i = 0; i < this.procPatternList.length; i++) {
                if (this.procPatternList[i].pattern == item.procPattern) {
                  this.procSelect = i;
                }
              }

              resolve(response);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;

              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;

            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    // 作業工程ﾊﾟﾀｰﾝ選択画面：ポップアップ閉じる
    closeDetailsInfo() {
      this.openDatailsAdd = false;
    },

    // 作業工程ﾊﾟﾀｰﾝ選択画面：選択
    addDetailsInfo() {
      this.inputList[this.index].procPattern = this.procPatternList[this.procSelect].pattern;
      this.openDatailsAdd = false;
    },

    // 作業工程ﾊﾟﾀｰﾝ追加画面：ポップアップ開く
    addProcPattern() {
      this.pattern = "";
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_INSPECTION_OFFICE, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 成功
            if (jsonData.resCom.resComCode == "000") {
              const list = [];
              jsonData.resIdv.proc.forEach((row) => {
                list.push({
                  order: "", //順番
                  procName: row.procName, //工程名
                  procCd: row.procCd, //工程コード
                });
              });
              this.procList = list;

              this.openProcPatternAdd = true;
              this.openDatailsAdd = false;
              if (this.$refs.ProcPatternAddPopup != undefined) {
                // バリデーションチェックをリセット
                this.$refs.ProcPatternAddPopup.resetValidation();
              }
              resolve(response);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;

              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;

            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    // 作業工程ﾊﾟﾀｰﾝ追加画面：ポップアップ閉じる
    closeProcPattern() {
      this.openProcPatternAdd = false;
      this.procPatternSelect(this.item);
      this.openDatailsAdd = true;
    },

    // 作業パターン登録ボタン押下
    registProcPatternMsg() {
      if (this.$refs.ProcPatternAddPopup.validate() && this.$refs.ProcPatternList.validate()) {
        //登録前チェック
        //パターンが重複している場合場合エラー
        let duplicatePattern = this.procPatternList.filter(
          (e) => e.pattern === Number(commonUtil.zen2han(this.pattern))
        );

        if (duplicatePattern.length != 0) {
          this.updateDialogMessage = true;
          this.backMessage = messsageUtil.getMessage("P-INI-008_002_E");
          return;
        }

        this.registList = [];
        for (var i = 0; i < this.procList.length; i++) {
          if (this.procList[i].order != "" && this.procList[i].order != null) {
            this.registList.push({
              procOrder: Number(commonUtil.zen2han(this.procList[i].order)), //順番
              procCd: Number(commonUtil.zen2han(this.procList[i].procCd)), //工程コード
            });
          }
        }
        //順番が入力されてない場合エラー
        if (this.registList.length == 0) {
          this.updateDialogMessage = true;
          this.backMessage = messsageUtil.getMessage("P-INI-008_003_E");
          return;
        }
        //順番が重複している場合場合エラー
        let uniqueUsers = this.registList.filter(
          (element, index, self) =>
            self.findIndex((e) => e.procOrder === element.procOrder) === index
        );

        if (uniqueUsers.length != this.registList.length) {
          this.updateDialogMessage = true;
          this.backMessage = messsageUtil.getMessage("P-INI-008_004_E");
          return;
        }
        this.confirmDialog.message = messsageUtil.getMessage("P-RCV-001_003_C");
        this.confirmDialog.isOpen = true;
        this.confirmDialog.screenFlag = true;
        this.confirmDialog.changeFlag = false;
        this.confirmDialog.okAction = this.registProcPattern;
      }
    },
    // 作業工程ﾊﾟﾀｰﾝ追加画面：登録
    registProcPattern() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // httpClientで使用する共通IFパラメータ等を作成する。
      const body = this.$httpClient.createRequestBodyConfig();
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_INI_008; // 画面ID
      // パラメータ設定
      body.reqIdv.clientSid = this.suppliersSelected; //取引先コード
      body.reqIdv.procPattern = this.pattern; //作業工程パターン
      body.reqIdv.procList = this.registList; //工程リスト

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.INI_DP_PROC_PATTERN_ADD, body, appConfig.APP_CONFIG)

          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              this.infoDialog.message = messsageUtil.getMessage("P-COM-001_005_C", [
                i18n.tc("btn.btn_insert"),
              ]);
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              this.infoDialog.outsideClickNotCloseFlg = false;
              // エラー時
            } else {
              reject(new Error(jsonData.resCom.resComMessage));
            }
          })
          .catch((ex) => {
            reject(ex);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.procPatternSelect(this.item);
            this.loadingCounter = 0;
          });
      }).catch((ex) => {
        this.infoDialog.message = ex.message;
        this.infoDialog.title = appConfig.DIALOG.title;
        this.infoDialog.isOpen = true;
        this.infoDialog.screenFlag = true;
        this.infoDialog.firstPageFlag = true;
      });
    },
    /**
     * 警告ダイアログ、OKボタン押下
     */
    updateOk() {
      this.updateDialogMessage = false;
    },
    workProcedureSelect(index) {
      //作業工程パターンが未の場合エラー
      if (this.inputList[index].procPattern == "未") {
        this.updateDialogMessage = true;
        this.backMessage = messsageUtil.getMessage("P-INI-008_006_E");
        return;
      }
      //作業手順メンテナンス遷移
      this.productSelectedName =
        this.inputList[index].productCnCd +
        " " +
        this.inputList[index].accessoryProductCd +
        "（" +
        this.inputList[index].productName +
        "）";
      let supplierCd =
        this.inputList[index].supplierCd + "（" + this.inputList[index].clientName + "）";
      //ユーザーメーカー区分名称取得
      let userMalersession = "";
      for (var i = 0; i < this.userMakerList.length; i++) {
        if (this.userMakerList[i].value == this.userMakerKbnSession) {
          userMalersession = this.userMakerList[i].text;
        }
      }
      this.$router.push({
        name: appConfig.MENU_ID.M_INI_WORK_PROCEDURE_MAINTENANCE,
        params: {
          userMakerKbn: this.userMakerKbnSession, // ユーザーメーカー区分
          userMaler: userMalersession, // ユーザーメーカー区分名称
          suppliersSelectedName: supplierCd, // 取引先コード：名称
          clientName: this.inputList[index].clientName, // 取引先名称
          productSelectedName: this.productSelectedName, // 品番/品名
          itemNm: this.inputList[index].productName, //品名
          suppliersSelected: this.inputList[index].clientSid, // 取引先SID
          productCodeSelected: this.inputList[index].itemStandardSid, // 商品標準SID
          procPattern: this.inputList[index].procPattern, // 作業工程パターン
          workContentSid: this.inputList[index].workContentSid, // 作業手順SID
          updateDatetime: this.inputList[index].updateDatetime, // 更新日時
          supplier: this.supplierSession,
          itemCd: this.itemCdSession,
          itemSubCd: this.itemSubCdSession,
        },
      });
    },
    inspectionSelect(index) {
      //作業工程パターンが未の場合エラー
      if (this.inputList[index].procPattern == "未") {
        this.updateDialogMessage = true;
        this.backMessage = messsageUtil.getMessage("P-INI-008_006_E");
        return;
      }
      //検査項目メンテナンス遷移
      this.productSelectedName =
        this.inputList[index].productCnCd +
        " " +
        this.inputList[index].accessoryProductCd +
        "（" +
        this.inputList[index].productName +
        "）";
      let supplierCd =
        this.inputList[index].supplierCd + "（" + this.inputList[index].clientName + "）";
      //ユーザーメーカー区分名称取得
      let userMalersession = "";
      for (var i = 0; i < this.userMakerList.length; i++) {
        if (this.userMakerList[i].value == this.userMakerKbnSession) {
          userMalersession = this.userMakerList[i].text;
        }
      }
      this.$router.push({
        name: appConfig.MENU_ID.M_INI_TEST_ITEM_MAINTENANCE,
        params: {
          userMakerKbn: this.userMakerKbnSession, // ユーザーメーカー区分
          userMaler: userMalersession, // ユーザーメーカー区分名称
          suppliersSelectedName: supplierCd, // 取引先コード：名称
          clientName: this.inputList[index].clientName, // 取引先名称
          productSelectedName: this.productSelectedName, // 品番/品名
          itemNm: this.inputList[index].productName, //品名
          suppliersSelected: this.inputList[index].clientSid, // 取引先SID
          productCodeSelected: this.inputList[index].itemStandardSid, // 商品標準SID
          procPattern: this.inputList[index].procPattern, // 作業工程パターン
          listGroup: this.inputList[index].listGroup, // 帳票Gr
          updateDatetime: this.inputList[index].updateDatetime, // 更新日時
          supplier: this.supplierSession,
          itemCd: this.itemCdSession,
          itemSubCd: this.itemSubCdSession,
        },
      });
    },
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },

    userMakerSected() {
      for (var i = 0; i < this.userMakerList.length; i++) {
        if (this.userMakerList[i].value == this.userMakerSected) {
          this.userMaler = this.userMakerList[i].text;
        }
      }
      if (this.userMakerSectedHint != this.userMakerSected) {
        this.suppliersSelected = "";
        this.itemSelected = "";
        this.accessoryProduct = "";
      }
      this.userMakerSectedHint = this.userMakerSected;
    },

    page: function (newValue) {
      this.page = newValue;
      this.getItemList("");
    },
    sortOptions: {
      handler(items) {
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? " desc" : " asc";
          this.sortItem = appConfig.P_MST_007_SORT[sortBy].replaceAll("{0}", ascdesc); // + ascdesc;
          this.getItemList();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
  created() {},
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}

.overflow {
  overflow: hidden;
}

.toatlNum {
  margin-left: 10px;
}

.txt-center {
  margin: auto;
}

.link {
  text-decoration: none;
}

.v-data-table {
  white-space: pre-line;
}

#listProcData {
  &.v-data-table--fixed-header ::v-deep {
    tr:nth-child(odd) {
      background-color: #dde5f0;
      height: 2rem;
    }

    th {
      font-size: large !important;
      color: black;
      background: #effad9;
      height: 2.5rem !important;

      &.asta::after {
        content: "*" !important;
        color: red;
      }

      &:not(:nth-child(0)) {
        &::before {
          content: "";
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    //テーブルデータ部分
    td {
      font-size: large;
    }
  }
}
</style>
